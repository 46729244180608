.App {
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 5vh;
}

.App > header {
  padding-left: 1vw;
  position: relative;
}

.App > header h1 {
  font-size: 50px;
  margin: 0px;
  font-family: 'Pacifico';
  font-weight: normal;
  margin-top: 2vh;
}
.App > header h2 {
  font-size: 15px;
  margin-top: 2px;
  color: #999999;
  font-weight: normal;
}

.App > header > .menu {
  position: absolute;
  right: 0px;
  bottom: 0px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  background-color: #efefef;
}

.main-wrap {
  align-items: center;
}

#circle {
  border-radius: 50%;
  position: relative;
  width: 40vmin;
  height: 40vmin;
  background-color: lightsalmon;
  margin: 5vh auto;
  z-index: 1;
}

#play-button {
  font-size: 48px;
}

#stop-button {
  font-size: 48px;
}

#circle-text {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1000;
  cursor: pointer;
  transform: translate(-50%, -50%);
  color: #eeeeee;
  user-select: none;
}

#inner-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -50% 0px 0px -50%;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  background-color: salmon;
  z-index: 500;
}

#scan-line {
  position: absolute;
  width: 100%;
  height: 5vmin;
  background-color: #efefef;
  opacity: 0.4;
  z-index: 1000;
  animation: moving 60s infinite linear;
}

#time-selector {
  text-align: center;
}

#menu {
  width: 15vmin;
  text-align: center;
  margin: 0 auto;
  display:none;
}

#script-now {
  font-size: 20px;
  font-weight: 300;
  margin: 18px;
}

#script-past {
  font-size: 15px;
  margin: 5px;
  color: #BBBBBB;
  min-height: 1.25em;
  line-height: 1.25;
}

#script-next {
  font-size: 15px;
  margin: 5px;
  color: #999999;
  min-height: 1.25em;
  line-height: 1.25;
}

#homescreen-add-banner {
  text-decoration: underline;
  cursor: pointer;
  text-align: center;
}

.slow-breathing {
  animation: deep-breath 10s linear infinite alternate;
}

.normal-breathing {
  animation: normal-breath 2s linear infinite alternate;
}

.fade-in {
  animation: fadein 0.5s 1;
}

.hide {
  display: none;
}

.bold {
  font-weight: bold;
}

.strike-through {
  text-decoration: line-through;
}

.strike-through:hover {
  text-decoration: line-through;
}

.strike-through:active {
  text-decoration: line-through;
}

.top-right {
  float:right;
}

/* ANIMATIONS */

@keyframes moving {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(70vmin);
  }
}


@keyframes deep-breath {
  0% {
    transform: scale(0.5);
  }
  45% {
    transform: scale(1);
  }
  55% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.5);
  }

}

@keyframes normal-breath {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
