body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 5vh;
}

.App > header {
  padding-left: 1vw;
  position: relative;
}

.App > header h1 {
  font-size: 50px;
  margin: 0px;
  font-family: 'Pacifico';
  font-weight: normal;
  margin-top: 2vh;
}
.App > header h2 {
  font-size: 15px;
  margin-top: 2px;
  color: #999999;
  font-weight: normal;
}

.App > header > .menu {
  position: absolute;
  right: 0px;
  bottom: 0px;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

body {
  background-color: #efefef;
}

.main-wrap {
  -webkit-align-items: center;
          align-items: center;
}

#circle {
  border-radius: 50%;
  position: relative;
  width: 40vmin;
  height: 40vmin;
  background-color: lightsalmon;
  margin: 5vh auto;
  z-index: 1;
}

#play-button {
  font-size: 48px;
}

#stop-button {
  font-size: 48px;
}

#circle-text {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1000;
  cursor: pointer;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  color: #eeeeee;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

#inner-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -50% 0px 0px -50%;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  background-color: salmon;
  z-index: 500;
}

#scan-line {
  position: absolute;
  width: 100%;
  height: 5vmin;
  background-color: #efefef;
  opacity: 0.4;
  z-index: 1000;
  -webkit-animation: moving 60s infinite linear;
          animation: moving 60s infinite linear;
}

#time-selector {
  text-align: center;
}

#menu {
  width: 15vmin;
  text-align: center;
  margin: 0 auto;
  display:none;
}

#script-now {
  font-size: 20px;
  font-weight: 300;
  margin: 18px;
}

#script-past {
  font-size: 15px;
  margin: 5px;
  color: #BBBBBB;
  min-height: 1.25em;
  line-height: 1.25;
}

#script-next {
  font-size: 15px;
  margin: 5px;
  color: #999999;
  min-height: 1.25em;
  line-height: 1.25;
}

#homescreen-add-banner {
  text-decoration: underline;
  cursor: pointer;
  text-align: center;
}

.slow-breathing {
  -webkit-animation: deep-breath 10s linear infinite alternate;
          animation: deep-breath 10s linear infinite alternate;
}

.normal-breathing {
  -webkit-animation: normal-breath 2s linear infinite alternate;
          animation: normal-breath 2s linear infinite alternate;
}

.fade-in {
  -webkit-animation: fadein 0.5s 1;
          animation: fadein 0.5s 1;
}

.hide {
  display: none;
}

.bold {
  font-weight: bold;
}

.strike-through {
  text-decoration: line-through;
}

.strike-through:hover {
  text-decoration: line-through;
}

.strike-through:active {
  text-decoration: line-through;
}

.top-right {
  float:right;
}

/* ANIMATIONS */

@-webkit-keyframes moving {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(70vmin);
            transform: translateY(70vmin);
  }
}

@keyframes moving {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(70vmin);
            transform: translateY(70vmin);
  }
}


@-webkit-keyframes deep-breath {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  55% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }

}


@keyframes deep-breath {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  55% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }

}

@-webkit-keyframes normal-breath {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes normal-breath {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

#circle {
  border-radius: 50%;
  position: relative;
  width: 40vmin;
  height: 40vmin;
  background-color: lightsalmon;
  margin: 5vh auto;
  z-index: 1;
}

#play-button {
  font-size: 48px;
}

#stop-button {
  font-size: 48px;
}

#circle-text {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1000;
  cursor: pointer;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  color: #eeeeee;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

#inner-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -50% 0px 0px -50%;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  background-color: salmon;
  z-index: 500;
}

#scan-line {
  position: absolute;
  width: 100%;
  height: 5vmin;
  background-color: #efefef;
  opacity: 0.4;
  z-index: 1000;
  -webkit-animation: moving 60s infinite linear;
          animation: moving 60s infinite linear;
}

.slow-breathing {
  -webkit-animation: deep-breath 10s linear infinite alternate;
          animation: deep-breath 10s linear infinite alternate;
}

.normal-breathing {
  -webkit-animation: normal-breath 2s linear infinite alternate;
          animation: normal-breath 2s linear infinite alternate;
}

/* ANIMATIONS */

@-webkit-keyframes moving {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(70vmin);
            transform: translateY(70vmin);
  }
}

@keyframes moving {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(70vmin);
            transform: translateY(70vmin);
  }
}


@-webkit-keyframes deep-breath {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  55% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }

}


@keyframes deep-breath {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  55% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }

}

@-webkit-keyframes normal-breath {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes normal-breath {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

