#circle {
  border-radius: 50%;
  position: relative;
  width: 40vmin;
  height: 40vmin;
  background-color: lightsalmon;
  margin: 5vh auto;
  z-index: 1;
}

#play-button {
  font-size: 48px;
}

#stop-button {
  font-size: 48px;
}

#circle-text {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1000;
  cursor: pointer;
  transform: translate(-50%, -50%);
  color: #eeeeee;
  user-select: none;
}

#inner-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -50% 0px 0px -50%;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  background-color: salmon;
  z-index: 500;
}

#scan-line {
  position: absolute;
  width: 100%;
  height: 5vmin;
  background-color: #efefef;
  opacity: 0.4;
  z-index: 1000;
  animation: moving 60s infinite linear;
}

.slow-breathing {
  animation: deep-breath 10s linear infinite alternate;
}

.normal-breathing {
  animation: normal-breath 2s linear infinite alternate;
}

/* ANIMATIONS */

@keyframes moving {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(70vmin);
  }
}


@keyframes deep-breath {
  0% {
    transform: scale(0.5);
  }
  45% {
    transform: scale(1);
  }
  55% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.5);
  }

}

@keyframes normal-breath {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
